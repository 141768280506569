<template>
  <div class="custom-tree-container">
    <div
      class="block"
      style="margin-top: 10px;"
    >
      <el-tree
        :data="data"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        :render-content="renderContent"
      >
      </el-tree>

      <!-- 新增编辑 -->
      <el-dialog
        :title="formData.id ? '编辑' : '新增'"
        :visible.sync="show_update"
        width="width"
      >
        <el-form
          ref="form"
          :model="formData"
          label-width="140px"
        >
          <el-form-item label="上级分类：">
            <SelectGuid
              width="250px"
              v-model="formData.pid"
              :disabled="guidDisabled"
              :show_update="show_update"
            ></SelectGuid>
          </el-form-item>
          <el-form-item
            label="分类名称："
            prop="name"
            :rules="rules"
          >
            <el-input
              style="width: 250px"
              v-model="formData.title"
              size="small"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button
            @click="show_update = false"
            size="small"
          >取 消</el-button>
          <el-button
            type="primary"
            @click="submit"
            size="small"
          >确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>

</template>
<script>
import SelectGuid from '@/views/biaoxun/guide/select-guide.vue'
import { getTreeAPI, editAPI, addAPI, delAPI } from './api'

export default {
  components: { SelectGuid },
  data () {
    return {
      show_update: false,
      data: null,
      formData: {
        id: 0,
        pid: 0,
        title: '',
        status: 0
      },
      guidDisabled: true,
      rules: {
      }
    }
  },
  mounted () { this.getTree() },
  methods: {
    async getTree () {
      const res = await getTreeAPI()
      this.data = JSON.parse(JSON.stringify(this.setLeaf(res)))

    },
    setLeaf (list) {
      return list.map((item) => {
        if (item.children && item.children.length > 0) {
          item.children = this.setLeaf(item.children)
        } else {
          item.leaf = true
          delete item.children
        }
        item.label = item.title
        return item
      })
    },
    add (data) {
      this.show_update = true
      this.guidDisabled = false
      this.formData.pid = data.id
    },
    edit (data) {
      this.show_update = true
      this.formData.id = data.id
      this.formData.pid = data.pid
      this.formData.title = data.label
    },
    async remove (node, data) {
      await delAPI(data.id)
      this.$message.success('删除成功')
      this.getTree()
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getTree()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.getTree()
            })
          }
        }
        this.formData.title = ''
        this.show_update = false
      })
    },
    renderContent (h, { node, data, store }) {
      const hasChildren = data.children && data.children.length > 0

      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span>
            <el-button size="mini" type="text" on-click={() => this.add(data)}>新增</el-button>
            <el-button size="mini" type="text" on-click={() => this.edit(data)}>编辑</el-button>
            <el-button size="mini"
              disabled={hasChildren} // 如果有子节点则禁用删除按钮
              type="text" on-click={() => this.remove(node, data)}>删除</el-button>
          </span>
        </span>)
    }
  }
};
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>